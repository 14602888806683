import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

window.addEventListener("message", function (e) {
  if(e.origin !== "https://site1.stsw.com.br") {
    return
  }

  const data = JSON.parse(e.data)

  if(typeof data.key !== "undefined"){
    localStorage.setItem("key", data.key)
    console.log(data.key)
  }
})

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
